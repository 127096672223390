// Generated by Framer (3140034)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["EQfrj3LUf"];

const variantClassNames = {EQfrj3LUf: "framer-v-z9ulz1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "EQfrj3LUf", title: p5NtCrAER = "Click", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "EQfrj3LUf", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-PQEVQ", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-z9ulz1", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"EQfrj3LUf"} ref={ref} style={{...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7RE0gU2Fucy01MDA=", "--framer-font-family": "\"DM Sans\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "500", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Click</motion.p></React.Fragment>} className={"framer-3pz27q"} fonts={["GF;DM Sans-500"]} layoutDependency={layoutDependency} layoutId={"kACPYBhYN"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={p5NtCrAER} transformTemplate={(_, t) => `translate(-50%, -50%) ${t}`} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-PQEVQ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PQEVQ * { box-sizing: border-box; }", ".framer-PQEVQ .framer-10df22o { display: block; }", ".framer-PQEVQ .framer-z9ulz1 { height: 24px; overflow: visible; position: relative; width: 47px; }", ".framer-PQEVQ .framer-3pz27q { flex: none; height: auto; left: 49%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 47
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"p5NtCrAER":"title"}
 */
const FramerNZ4fOWk99: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerNZ4fOWk99;

FramerNZ4fOWk99.displayName = "Bttn";

FramerNZ4fOWk99.defaultProps = {height: 24, width: 47};

addPropertyControls(FramerNZ4fOWk99, {p5NtCrAER: {defaultValue: "Click", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerNZ4fOWk99, [{family: "DM Sans", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/NZ4fOWk99:default", url: "https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrOB-sClQX6Cg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrOB-sClQX6Cg.ttf", weight: "500"}])